import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import profiles, { ProfileOption, TaxPlan } from '../profiles';
import { formatter } from './helpers';
import SimpleContainer from './SimpleContainer';
import GraphicButton from './GraphicButton';
import { GuiIcons, ProfileIcons } from '../images/Images';

const ProfileText = styled.p`
	line-height: ${props => props.theme.fontSize};
	margin: 4px 0;
`;

interface ProfileSelectProps {
	selection?: string;
	updateProfile: (profile: ProfileOption) => void;
	updateTaxes: (taxes?: TaxPlan[]) => void;
}

const ProfileSelect = ({
	selection,
	updateProfile,
	updateTaxes,
}: ProfileSelectProps) => {
	return (
		<div>
			<h2>Select a Profile or Create a Scenario</h2>
			<SimpleContainer $rows={3} $columns={3} $gridGap="1rem">
				<GraphicButton
					onClick={() => {
						updateProfile('custom');
						updateTaxes(undefined);
					}}
					selected={'custom' === selection}
				>
					{GuiIcons.plus()}
					<div>
						<ProfileText>Create a Custom Scenario</ProfileText>
					</div>
				</GraphicButton>
				{profiles.map(p => {
					return (
						<GraphicButton
							data-tooltip-id={`profile-${p.description.id}`}
							key={`profile-${p.description.id}`}
							onClick={() => {
								updateProfile(p.description.id);
								updateTaxes(p.taxes);
							}}
							selected={p.description.id === selection}
						>
							{ProfileIcons[p.description.id]()}
							<div>
								<ProfileText style={{ fontWeight: 700 }}>
									{p.description.name}
								</ProfileText>
								<ProfileText>{p.description.filingData}</ProfileText>
								<ProfileText>
									{formatter(p.description.income, 'shortDollar')}
								</ProfileText>
							</div>
							{p.description.tooltip ? (
								<Tooltip id={`profile-${p.description.id}`}>
									<p style={{ maxWidth: '300px' }}>{p.description.tooltip}</p>
								</Tooltip>
							) : null}
						</GraphicButton>
					);
				})}
			</SimpleContainer>
		</div>
	);
};

export default ProfileSelect;
