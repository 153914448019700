import { Format } from './components/helpers';
import { TaxPlan } from './profiles';

export interface ResultsMetadata {
	id: keyof TaxPlan['results'];
	name: string;
	tooltip: string;
	desirable?: boolean;
	format: Format;
}

export const resultsMetadata = [
	{
		id: 'cash_income',
		name: 'Cash Income',
		tooltip:
			"Income from all sources plus the employer's share of payroll taxes.",
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'employee_payroll_tax',
		name: 'Employee Payroll Tax',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'employer_payroll_tax',
		name: 'Employer Payroll Tax',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'total_payroll_tax',
		name: 'Total Payroll Tax',
		tooltip: 'Includes both employer and employee shares.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'ordinary_income_after_401k',
		name: 'Ordinary Income after 401(k)',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'gross_income',
		name: 'Ordinary Income',
		tooltip: 'Total cash income minus employer payroll taxes.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'agi',
		name: 'Adjusted Gross Income',
		tooltip:
			'Total income subject to tax after adjustments for exclusions (including 401k contributions) and additions.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'taxable_income',
		name: 'Taxable Income',
		tooltip:
			'Adjusted gross income minus personal exemptions and the standard or itemized deductions allowed under the regular income tax.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'deduction_type',
		name: 'Deduction Type',
		tooltip: '',
		desirable: false,
		format: 'text',
	},
	{
		id: 'qbi_ded',
		name: 'Qualified Business Income Deduction',
		tooltip: '',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'deductions',
		name: 'Deductions',
		tooltip: '(Includes Pease Limitation when applicable)',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'personal_exemption_amt',
		name: 'Personal Exemption Amount',
		tooltip: '',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'pease_limitation_amt',
		name: 'Pease Limitation Amount',
		tooltip:
			'Amount by which a taxpayer’s itemized deductions are limited by the overall limitation on itemized deductions.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'income_tax_before_credits',
		name: 'Income Tax before Credits',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'qualified_income_tax',
		name: 'Qualified Income Tax',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'selected_tax_before_credits',
		name: 'Income Tax Before Credits',
		tooltip: '(Does not include AMT liability)',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'amt_taxable_income',
		name: 'AMT Taxable Income',
		tooltip:
			'Regular taxable income plus AMT adjustments minus applicable AMT exemption.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'amt',
		name: 'AMT Liability',
		tooltip: '(in addition to regular tax liability)',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'medicare_surtax',
		name: 'Medicare Surtax',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'income_tax_before_credits_with_amt',
		name: 'Income Tax Before Credits',
		tooltip: '(Includes AMT liability)',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'ctc',
		name: 'Child Tax Credit',
		tooltip: 'Nonrefundable portion of the Child Tax Credit.',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'dep_credit',
		name: 'Non-Child Dependent Credit',
		tooltip: '',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'actc',
		name: 'Additional Child Tax Credit',
		tooltip: 'Refundable Portion of Child Tax Credit',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'eitc',
		name: 'Earned Income Tax Credit',
		tooltip: '',
		desirable: true,
		format: 'longDollar',
	},
	{
		id: 'income_tax_after_nonrefundable_credits',
		name: 'Income Tax after Non-refundable Credits',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'niit',
		name: 'Net Investment Income Tax',
		tooltip:
			"Additional tax on high-income taxpayers' investment income above specified thresholds.",
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'medicare_surtax',
		name: 'Additional Medicare Tax',
		tooltip:
			"Additional tax on high-income taxpayers' earnings above specified thresholds.",
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'sched_se_tax',
		name: 'Self-Employment Tax',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'income_tax_after_credits',
		name: 'Income Tax after Credits',
		tooltip:
			'Income Tax Liability Amount of income tax owed, including any credits, AMT, the NIIT, and the medicare surtax.',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'income_tax_after_other_taxes',
		name: 'Income Tax After Other Taxes',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'tax_burden',
		name: 'Tax Burden',
		tooltip: '',
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'tax_wedge',
		name: 'Total Tax Liability',
		tooltip:
			"Total tax liability equals the household's income tax liability plus its payroll tax liability.",
		desirable: false,
		format: 'longDollar',
	},
	{
		id: 'avg_effective_tax_rate',
		name: 'Avg. Effective Tax Rate',
		tooltip:
			'Income tax liability measured as a percentage of total cash income.',
		desirable: false,
		format: 'percent',
	},
	{
		id: 'avg_effective_tax_rate_wo_payroll',
		name: 'Avg. Effective Tax Rate w/o Payroll',
		tooltip: '',
		desirable: false,
		format: 'percent',
	},
	{
		id: 'marginal_income_tax_rate',
		name: 'Marginal Income Tax Rate',
		tooltip: '',
		desirable: false,
		format: 'percent',
	},
	{
		id: 'marginal_business_income_tax_rate',
		name: 'Marginal Business Income Tax Rate',
		tooltip: '',
		desirable: false,
		format: 'percent',
	},
	{
		id: 'take_home_pay',
		name: 'Take-Home Pay',
		tooltip: '',
		desirable: true,
		format: 'longDollar',
	},
] as const satisfies ResultsMetadata[];
