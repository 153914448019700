import styled, { keyframes } from 'styled-components';
import SimpleContainer from '../SimpleContainer';
import TaxTable from './TaxTable';
import SocialButtons from '../SocialButtons';
import ColorizeNumber from '../ColorizeNumber';
import { formatter, colorForValue } from '../helpers';
import { TaxPlan } from '../../profiles';
import Theme from '../../Theme';

const Animation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
	animation: ${Animation} 0.6s;
`;

interface SummaryBoxStyleProps {
	primary?: boolean;
}

const SummaryBoxStyle = styled.div<SummaryBoxStyleProps>`
	background-color: ${props =>
		props.primary ? props.theme.tfBlueHover : '#fff'};
	display: grid;
	grid-template: auto 1fr / auto;
	margin-bottom: 1rem;
	text-align: center;

	@media (min-width: ${props => props.theme.mobileWidth}) {
		grid-template-columns: 1fr;
		grid-template-rows: subgrid;
		grid-row: span 2;
		margin-bottom: 0;
	}
`;

interface SummaryBoxTitleProps {
	$main?: boolean;
	color?: string;
}

const SummaryBoxTitle = styled.div<SummaryBoxTitleProps>`
	background-color: ${props => (props.color ? props.color : '#fff')};
	border: 1px solid ${props => (props.color ? props.color : props.theme.border)};
	color: ${props => (props.color ? '#fff' : '#333')};
	font-size: ${props => (props.$main ? '18px' : '14px')};
	font-weight: 400;
	line-height: 1.3;
	margin: 0;
	padding: 0.5rem 1rem;
`;

interface SummaryBoxValueProps {
	$main?: boolean;
}

const SummaryBoxValue = styled.div<SummaryBoxValueProps>`
	border: 1px solid ${props => props.theme.border};
	border-top: 0;
	font-size: ${props => (props.$main ? 'calc(20px + 1vw)' : '20px')};
	line-height: ${props => (props.$main ? '40px' : '24px')};
	padding: ${props => (props.$main ? '24px' : '12px')} 0;

	@media (min-width: 720px) {
		font-size: ${props => (props.$main ? '32px' : '20px')};
	}
`;

interface SummaryBoxProps {
	title: string;
	value: React.ReactNode;
	$main?: boolean;
	color?: string;
}

const SummaryBox = (props: SummaryBoxProps) => {
	return (
		<SummaryBoxStyle>
			<SummaryBoxTitle $main={props.$main} color={props.color}>
				{props.title}
			</SummaryBoxTitle>
			<SummaryBoxValue $main={props.$main}>{props.value}</SummaryBoxValue>
		</SummaryBoxStyle>
	);
};

interface ComparisonSummaryProps {
	plan1?: TaxPlan;
	plan2?: TaxPlan;
}

function ComparisonSummary({ plan1, plan2 }: ComparisonSummaryProps) {
	if (!plan1 || !plan2) {
		return null;
	}

	const burdenDelta = plan2.results.tax_wedge - plan1.results.tax_wedge;
	const takeHomeDelta =
		plan2.results.take_home_pay - plan1.results.take_home_pay;
	let afterTaxPercentDelta = takeHomeDelta / plan1.results.take_home_pay;

	if (isNaN(afterTaxPercentDelta)) {
		afterTaxPercentDelta = 0;
	}

	return (
		<>
			<Container>
				<SimpleContainer $rows={2} $columns={3} $gridGap="0 1rem">
					<SummaryBox
						title={plan1.plan.shortName}
						value={formatter(plan1.results.tax_wedge, 'longDollar')}
						$main={true}
					/>
					<SummaryBox
						title={plan2.plan.shortName}
						value={formatter(plan2.results.tax_wedge, 'longDollar')}
						color={Theme.primary}
						$main={true}
					/>
					<SummaryBox
						title={`Tax ${burdenDelta <= 0 ? 'Cut' : 'Increase'}`}
						value={
							<ColorizeNumber sign="-" value={burdenDelta}>
								{formatter(burdenDelta, 'deltaDollar')}
							</ColorizeNumber>
						}
						color={colorForValue(burdenDelta, '-')}
						$main={true}
					/>
				</SimpleContainer>
				<SimpleContainer $rows={1} $columns={2} $gridGap="0 1rem">
					<SummaryBox
						title="% Change in Take-Home Pay"
						value={
							<ColorizeNumber sign="+" value={afterTaxPercentDelta}>
								{formatter(afterTaxPercentDelta, 'percent')}
							</ColorizeNumber>
						}
					/>
					<SummaryBox
						title="Share the Results"
						value={
							<SocialButtons
								size="25px"
								message="I used this new tax reform calculator to see how the Tax Cuts and Jobs Act will impact after-tax incomes."
								hashtags="TaxReform"
								emailSubject="Tax Reform Calculator"
								emailBody={`The Tax Foundation's tax reform calculator shows you how the Tax Cuts and Jobs Act will impact after-tax income, total taxes owed, effective tax rates, and more. You can calculate a custom scenario here: ${window.location.href}`}
							/>
						}
					/>
				</SimpleContainer>
				<p style={{ fontSize: 12 }}>
					The <a href="https://taxfoundation.org">Tax Foundation</a>&apos;s tax
					calculator is for estimation of the impact of the TCJA on taxpayers.
					It does not fully represent all potential tax scenarios and
					liabilities, and should not be used for tax preparation purposes. This
					calculator is for educational use only.
				</p>
			</Container>
			<Container>
				<TaxTable plan1={plan1} plan2={plan2} />
			</Container>
		</>
	);
}

export default ComparisonSummary;
