import { DefaultTheme } from 'styled-components';

export const Palette = {
	yellow: 'hsl(47, 100%, 62%)',
	'yellow-200': 'hsl(47, 100%, 50%)',
	'yellow-100': 'hsl(47, 100%, 40%)',
	navy: 'hsl(201, 28%, 12%)',
	'navy-200': 'hsl(210, 61%, 22%)',
	'navy-100': 'hsl(203, 29%, 12%)',
	blue: 'hsl(210, 58%, 33%)',
	green: 'hsl(148, 59%, 58%)',
	greenText: '#228751',
	'green-100': 'hsl(148, 59%, 70%)',
	'green-900': 'hsl(148deg 60.29% 24.32%)',
	'gray-900': 'hsl(0, 0%, 97%)',
	'gray-800': 'hsl(0, 0%, 80%)',
	'gray-700': 'hsl(0, 0%, 70%)',
	'gray-600': 'hsl(0, 0%, 60%)',
	'gray-500': 'hsl(0, 0%, 50%)',
	'gray-400': 'hsl(0, 0%, 40%)',
	'gray-300': 'hsl(0, 0%, 30%)',
	'gray-200': 'hsl(0, 0%, 20%)',
	'gray-100': 'hsl(0, 0%, 10%)',
	white: 'hsl(0, 0%, 100%)',
	black: 'hsl(0, 0%, 0%)',
};

const Theme: DefaultTheme = {
	color: Palette.navy,
	fontFamily: '"Roboto Flex", sans-serif',
	fontFamilyMono: '"Roboto Mono", monospace',
	fontSize: '16px',
	h1Size: '2em',
	h2Size: '1.5em',
	mobileWidth: '480px',
	tfBlue: '#0094ff',
	tfBlueHighlight: '#4db4ff',
	tfBlueHover: '#e6f4ff',
	navy: Palette.navy,
	primary: Palette.blue,
	primaryHover: Palette['navy-200'],
	secondary: Palette.yellow,
	secondaryHover: Palette['yellow-200'],
	tertiary: Palette.green,
	tertiaryHover: Palette['green-100'],
	border: Palette['gray-800'],
	gray900: Palette['gray-900'],
	green: Palette.green,
	greenText: Palette.greenText,
	green100: Palette['green-100'],
	green900: Palette['green-900'],
};

export default Theme;
