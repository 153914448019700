export const plans = [
	'current',
	'TCJA_perm_2026',
	'TCJA_expansion_2026',
] as const;

export type PlanId = (typeof plans)[number];

export interface Plan {
	id: PlanId;
	name: string;
	shortName: string;
	year: number;
}

export interface TaxPlan {
	plan: Plan;
	results: {
		gross_income: number;
		employee_payroll_tax: number;
		employer_payroll_tax: number;
		ordinary_income_after_401k: number;
		agi: number;
		taxable_income: number;
		deduction_type: string;
		deductions: number;
		personal_exemption_amt: number;
		pease_limitation_amt: number;
		qbi_ded: number;
		income_tax_before_credits: number;
		qualified_income_tax: number;
		selected_tax_before_credits: number;
		amt_taxable_income: number;
		amt: number;
		income_tax_before_credits_with_amt: number;
		ctc: number;
		actc: number;
		eitc: number;
		dep_credit: number;
		income_tax_after_nonrefundable_credits: number;
		medicare_surtax: number;
		niit: number;
		sched_se_tax: number;
		income_tax_after_other_taxes: number;
		income_tax_after_credits: number;
		total_payroll_tax: number;
		cash_income: number;
		tax_burden: number;
		tax_wedge: number;
		take_home_pay: number;
		avg_effective_tax_rate: number;
		avg_effective_tax_rate_wo_payroll: number;
		marginal_income_tax_rate: number;
		marginal_business_income_tax_rate: number;
	};
}

export interface Profile {
	description: {
		name: string;
		filingData: string;
		id: string;
		income: number;
		tooltip: string;
	};
	taxes: TaxPlan[];
}

const profiles = [
	{
		description: {
			name: 'James',
			filingData: 'Single, No Kids',
			id: 'james',
			income: 30000,
			tooltip:
				'This taxpayer takes the standard deduction and had $2,600 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 30000,
					employee_payroll_tax: 2295.0,
					employer_payroll_tax: 2295.0,
					ordinary_income_after_401k: 27400,
					agi: 27400,
					taxable_income: 13800.0,
					deduction_type: 'standard',
					deductions: 8300.0,
					personal_exemption_amt: 5300.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 1463.75,
					qualified_income_tax: 1463.75,
					selected_tax_before_credits: 1463.75,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 1463.75,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 1463.75,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 1463.75,
					income_tax_after_credits: 1463.75,
					total_payroll_tax: 4590.0,
					cash_income: 32295.0,
					tax_burden: 3758.75,
					tax_wedge: 6053.75,
					take_home_pay: 26241.25,
					avg_effective_tax_rate: 0.18745161789750736,
					avg_effective_tax_rate_wo_payroll: 0.048791666666666664,
					marginal_income_tax_rate: 0.2265,
					marginal_business_income_tax_rate: 0.2807,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 30000,
					employee_payroll_tax: 2295.0,
					employer_payroll_tax: 2295.0,
					ordinary_income_after_401k: 27400,
					agi: 27400,
					taxable_income: 12100.0,
					deduction_type: 'standard',
					deductions: 15300.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 1210.0,
					qualified_income_tax: 1210.0,
					selected_tax_before_credits: 1210.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 1210.0,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 1210.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 1210.0,
					income_tax_after_credits: 1210.0,
					total_payroll_tax: 4590.0,
					cash_income: 32295.0,
					tax_burden: 3505.0,
					tax_wedge: 5800.0,
					take_home_pay: 26495.0,
					avg_effective_tax_rate: 0.17959436445270166,
					avg_effective_tax_rate_wo_payroll: 0.04033333333333333,
					marginal_income_tax_rate: 0.1961,
					marginal_business_income_tax_rate: 0.22842000000000007,
				},
			},
		],
	},
	{
		description: {
			name: 'Jason',
			filingData: 'Single, 2 Kids',
			id: 'jason',
			income: 52000,
			tooltip:
				'This taxpayer takes the standard deduction and had $4,000 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 52000,
					employee_payroll_tax: 3978.0,
					employer_payroll_tax: 3978.0,
					ordinary_income_after_401k: 48000,
					agi: 48000,
					taxable_income: 19950.0,
					deduction_type: 'standard',
					deductions: 12150.0,
					personal_exemption_amt: 15900.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 2127.5,
					qualified_income_tax: 2127.5,
					selected_tax_before_credits: 2127.5,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 2127.5,
					ctc: 2000.0,
					actc: 0,
					eitc: 1355.03,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 127.5,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 127.5,
					income_tax_after_credits: -1227.53,
					total_payroll_tax: 7956.0,
					cash_income: 55978.0,
					tax_burden: 2750.4700000000003,
					tax_wedge: 6728.47,
					take_home_pay: 49249.53,
					avg_effective_tax_rate: 0.12019847082782523,
					avg_effective_tax_rate_wo_payroll: -0.023606346153846155,
					marginal_income_tax_rate: 0.4371039999999999,
					marginal_business_income_tax_rate: 0.2807,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 52000,
					employee_payroll_tax: 3978.0,
					employer_payroll_tax: 3978.0,
					ordinary_income_after_401k: 48000,
					agi: 48000,
					taxable_income: 25000.0,
					deduction_type: 'standard',
					deductions: 23000.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 2653.0,
					qualified_income_tax: 2653.0,
					selected_tax_before_credits: 2653.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 2653.0,
					ctc: 400.0,
					actc: 3600.0,
					eitc: 1355.03,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 2253.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 2253.0,
					income_tax_after_credits: -2702.03,
					total_payroll_tax: 7956.0,
					cash_income: 55978.0,
					tax_burden: 1275.9699999999998,
					tax_wedge: 5253.969999999999,
					take_home_pay: 50724.03,
					avg_effective_tax_rate: 0.09385776555075206,
					avg_effective_tax_rate_wo_payroll: -0.05196211538461539,
					marginal_income_tax_rate: 0.4071040000000001,
					marginal_business_income_tax_rate: 0.22882000000000008,
				},
			},
		],
	},
	{
		description: {
			name: 'Amber',
			filingData: 'Single, No Kids',
			id: 'amber',
			income: 75000,
			tooltip:
				'This taxpayer takes the standard deduction and had $5,500 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 75000,
					employee_payroll_tax: 5737.5,
					employer_payroll_tax: 5737.5,
					ordinary_income_after_401k: 69500,
					agi: 69500,
					taxable_income: 55900.0,
					deduction_type: 'standard',
					deductions: 8300.0,
					personal_exemption_amt: 5300.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 8443.75,
					qualified_income_tax: 8443.75,
					selected_tax_before_credits: 8443.75,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 8443.75,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 8443.75,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 8443.75,
					income_tax_after_credits: 8443.75,
					total_payroll_tax: 11475.0,
					cash_income: 80737.5,
					tax_burden: 14181.25,
					tax_wedge: 19918.75,
					take_home_pay: 60818.75,
					avg_effective_tax_rate: 0.24671001703050008,
					avg_effective_tax_rate_wo_payroll: 0.11258333333333333,
					marginal_income_tax_rate: 0.3265,
					marginal_business_income_tax_rate: 0.3736360000000001,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 75000,
					employee_payroll_tax: 5737.5,
					employer_payroll_tax: 5737.5,
					ordinary_income_after_401k: 69500,
					agi: 69500,
					taxable_income: 54200.0,
					deduction_type: 'standard',
					deductions: 15300.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 6736.0,
					qualified_income_tax: 6736.0,
					selected_tax_before_credits: 6736.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 6736.0,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 6736.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 6736.0,
					income_tax_after_credits: 6736.0,
					total_payroll_tax: 11475.0,
					cash_income: 80737.5,
					tax_burden: 12473.5,
					tax_wedge: 18211.0,
					take_home_pay: 62526.5,
					avg_effective_tax_rate: 0.22555813593435517,
					avg_effective_tax_rate_wo_payroll: 0.08981333333333333,
					marginal_income_tax_rate: 0.2965,
					marginal_business_income_tax_rate: 0.3017520000000004,
				},
			},
		],
	},
	{
		description: {
			name: 'Kavya & Nick',
			filingData: 'Married, 2 Kids',
			id: 'kavya',
			income: 85000,
			tooltip:
				'These taxpayers take the standard deduction and had $5,500 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 85000,
					employee_payroll_tax: 6502.5,
					employer_payroll_tax: 6502.5,
					ordinary_income_after_401k: 79500,
					agi: 79500,
					taxable_income: 41700.0,
					deduction_type: 'standard',
					deductions: 16600.0,
					personal_exemption_amt: 21200.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 5042.5,
					qualified_income_tax: 5042.5,
					selected_tax_before_credits: 5042.5,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 5042.5,
					ctc: 2000.0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 3042.5,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 3042.5,
					income_tax_after_credits: 3042.5,
					total_payroll_tax: 13005.0,
					cash_income: 91502.5,
					tax_burden: 9545.0,
					tax_wedge: 16047.5,
					take_home_pay: 75455.0,
					avg_effective_tax_rate: 0.17537772192016612,
					avg_effective_tax_rate_wo_payroll: 0.035794117647058823,
					marginal_income_tax_rate: 0.2265,
					marginal_business_income_tax_rate: 0.2807,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 85000,
					employee_payroll_tax: 6502.5,
					employer_payroll_tax: 6502.5,
					ordinary_income_after_401k: 79500,
					agi: 79500,
					taxable_income: 48900.0,
					deduction_type: 'standard',
					deductions: 30600.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 5381.0,
					qualified_income_tax: 5381.0,
					selected_tax_before_credits: 5381.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 5381.0,
					ctc: 4000.0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 1381.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 1381.0,
					income_tax_after_credits: 1381.0,
					total_payroll_tax: 13005.0,
					cash_income: 91502.5,
					tax_burden: 7883.5,
					tax_wedge: 14386.0,
					take_home_pay: 77116.5,
					avg_effective_tax_rate: 0.15721974809431435,
					avg_effective_tax_rate_wo_payroll: 0.016247058823529413,
					marginal_income_tax_rate: 0.1965,
					marginal_business_income_tax_rate: 0.22881999999999972,
				},
			},
		],
	},
	{
		description: {
			name: 'Sophie & Chad',
			filingData: 'Married, 2 Kids',
			id: 'sophie',
			income: 165000,
			tooltip:
				'These taxpayers claimed about $26,000 worth of itemized deductions and had $20,000 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 165000,
					employee_payroll_tax: 12622.5,
					employer_payroll_tax: 12622.5,
					ordinary_income_after_401k: 145000,
					agi: 145000,
					taxable_income: 98060.0,
					deduction_type: 'itemized',
					deductions: 25740,
					personal_exemption_amt: 21200.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 13496.5,
					qualified_income_tax: 13496.5,
					selected_tax_before_credits: 13496.5,
					amt_taxable_income: 21210.0,
					amt: 0,
					income_tax_before_credits_with_amt: 13496.5,
					ctc: 250.0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 13246.5,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 13246.5,
					income_tax_after_credits: 13246.5,
					total_payroll_tax: 25245.0,
					cash_income: 177622.5,
					tax_burden: 25869.0,
					tax_wedge: 38491.5,
					take_home_pay: 139131.0,
					avg_effective_tax_rate: 0.21670396486931554,
					avg_effective_tax_rate_wo_payroll: 0.08028181818181818,
					marginal_income_tax_rate: 0.3689,
					marginal_business_income_tax_rate: 0.4160360000000001,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 165000,
					employee_payroll_tax: 12622.5,
					employer_payroll_tax: 12622.5,
					ordinary_income_after_401k: 145000,
					agi: 145000,
					taxable_income: 114400.0,
					deduction_type: 'standard',
					deductions: 30600.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 14796.0,
					qualified_income_tax: 14796.0,
					selected_tax_before_credits: 14796.0,
					amt_taxable_income: 5300.0,
					amt: 0,
					income_tax_before_credits_with_amt: 14796.0,
					ctc: 4000.0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 10796.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 10796.0,
					income_tax_after_credits: 10796.0,
					total_payroll_tax: 25245.0,
					cash_income: 177622.5,
					tax_burden: 23418.5,
					tax_wedge: 36041.0,
					take_home_pay: 141581.5,
					avg_effective_tax_rate: 0.2029078523272671,
					avg_effective_tax_rate_wo_payroll: 0.06543030303030303,
					marginal_income_tax_rate: 0.2965,
					marginal_business_income_tax_rate: 0.30175199999999897,
				},
			},
		],
	},
	{
		description: {
			name: 'Soren & Linea',
			filingData: 'Married, 3 Kids',
			id: 'soren',
			income: 200000,
			tooltip:
				'These taxpayers earned half their income via a pass-through business which qualifies for the new 20-percent deduction and claimed about $30,000 worth of other itemized deductions. They also had $19,000 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 200000,
					employee_payroll_tax: 7650.0,
					employer_payroll_tax: 7650.0,
					ordinary_income_after_401k: 81000,
					agi: 173935.225,
					taxable_income: 117095.225,
					deduction_type: 'itemized',
					deductions: 30340,
					personal_exemption_amt: 26500.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 18211.31,
					qualified_income_tax: 18211.31,
					selected_tax_before_credits: 18211.31,
					amt_taxable_income: 50145.225000000006,
					amt: 0,
					income_tax_before_credits_with_amt: 18211.31,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 18211.31,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 14129.55,
					income_tax_after_other_taxes: 32340.86,
					income_tax_after_credits: 32340.86,
					total_payroll_tax: 15300.0,
					cash_income: 207650.0,
					tax_burden: 39990.86,
					tax_wedge: 47640.86,
					take_home_pay: 160009.14,
					avg_effective_tax_rate: 0.22942865398507103,
					avg_effective_tax_rate_wo_payroll: 0.1617043,
					marginal_income_tax_rate: 0.3265,
					marginal_business_income_tax_rate: 0.3736320000000007,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 200000,
					employee_payroll_tax: 7650.0,
					employer_payroll_tax: 7650.0,
					ordinary_income_after_401k: 81000,
					agi: 173935.225,
					taxable_income: 123335.225,
					deduction_type: 'standard',
					deductions: 50600.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 20000.0,
					income_tax_before_credits: 16761.75,
					qualified_income_tax: 16761.75,
					selected_tax_before_credits: 16761.75,
					amt_taxable_income: 34235.225000000006,
					amt: 0,
					income_tax_before_credits_with_amt: 16761.75,
					ctc: 6000.0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 10761.75,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 14129.55,
					income_tax_after_other_taxes: 24891.3,
					income_tax_after_credits: 24891.3,
					total_payroll_tax: 15300.0,
					cash_income: 207650.0,
					tax_burden: 32541.3,
					tax_wedge: 40191.3,
					take_home_pay: 167458.7,
					avg_effective_tax_rate: 0.1935530941488081,
					avg_effective_tax_rate_wo_payroll: 0.1244565,
					marginal_income_tax_rate: 0.29650000000000143,
					marginal_business_income_tax_rate: 0.3017520000000004,
				},
			},
		],
	},
	{
		description: {
			name: 'Laura & Seth',
			filingData: 'Married, 2 Kids',
			id: 'laura',
			income: 2000000,
			tooltip:
				'These taxpayers claimed about $220,000 worth of itemized deductions and had $18,500 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 2000000,
					employee_payroll_tax: 39453.2,
					employer_payroll_tax: 39453.2,
					ordinary_income_after_401k: 1981500,
					agi: 1981500,
					taxable_income: 1806370.0,
					deduction_type: 'itemized',
					deductions: 175130.0,
					personal_exemption_amt: 0,
					pease_limitation_amt: 47223.0,
					qbi_ded: 0,
					income_tax_before_credits: 644217.92,
					qualified_income_tax: 644217.92,
					selected_tax_before_credits: 644217.92,
					amt_taxable_income: 1896500.0,
					amt: 0,
					income_tax_before_credits_with_amt: 644217.92,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 644217.92,
					medicare_surtax: 15749.999999999998,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 659967.92,
					income_tax_after_credits: 659967.92,
					total_payroll_tax: 78906.4,
					cash_income: 2039453.2,
					tax_burden: 699421.12,
					tax_wedge: 738874.32,
					take_home_pay: 1300578.88,
					avg_effective_tax_rate: 0.36229040215289077,
					avg_effective_tax_rate_wo_payroll: 0.32998396,
					marginal_income_tax_rate: 0.4313799999999814,
					marginal_business_income_tax_rate: 0.5203599999999627,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 2000000,
					employee_payroll_tax: 39453.2,
					employer_payroll_tax: 39453.2,
					ordinary_income_after_401k: 1981500,
					agi: 1981500,
					taxable_income: 1885397.0,
					deduction_type: 'itemized',
					deductions: 96103.0,
					personal_exemption_amt: 0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 620167.89,
					qualified_income_tax: 620167.89,
					selected_tax_before_credits: 620167.89,
					amt_taxable_income: 1901500.0,
					amt: 0,
					income_tax_before_credits_with_amt: 620167.89,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 620167.89,
					medicare_surtax: 15749.999999999998,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 635917.89,
					income_tax_after_credits: 635917.89,
					total_payroll_tax: 78906.4,
					cash_income: 2039453.2,
					tax_burden: 675371.09,
					tax_wedge: 714824.2899999999,
					take_home_pay: 1324628.9100000001,
					avg_effective_tax_rate: 0.35049801093744143,
					avg_effective_tax_rate_wo_payroll: 0.317958945,
					marginal_income_tax_rate: 0.3935,
					marginal_business_income_tax_rate: 0.4111560000000056,
				},
			},
		],
	},
	{
		description: {
			name: 'James & Eddie',
			filingData: 'Married, Retired',
			id: 'joe',
			income: 48000,
			tooltip:
				'This taxpayer takes the standard deduction and had $2,600 of pre-tax 401(k) contributions.',
		},
		taxes: [
			{
				plan: {
					id: 'current',
					name: 'Tax Cuts and Jobs Act Expires',
					shortName: 'TCJA Expires',
					year: 2026,
				},
				results: {
					gross_income: 48000,
					employee_payroll_tax: 2386.8,
					employer_payroll_tax: 2386.8,
					ordinary_income_after_401k: 31200,
					agi: 35000.0,
					taxable_income: 4500.0,
					deduction_type: 'standard',
					deductions: 19900.0,
					personal_exemption_amt: 10600.0,
					pease_limitation_amt: 0,
					qbi_ded: 0,
					income_tax_before_credits: 450.0,
					qualified_income_tax: 450.0,
					selected_tax_before_credits: 450.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 450.0,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0,
					income_tax_after_nonrefundable_credits: 450.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 450.0,
					income_tax_after_credits: 450.0,
					total_payroll_tax: 4773.6,
					cash_income: 50386.8,
					tax_burden: 2836.8,
					tax_wedge: 5223.6,
					take_home_pay: 45163.200000000004,
					avg_effective_tax_rate: 0.10367000881183167,
					avg_effective_tax_rate_wo_payroll: 0.009375,
					marginal_income_tax_rate: 0.2265,
					marginal_business_income_tax_rate: 0.2807,
				},
			},
			{
				plan: {
					id: 'TCJA_perm_2026',
					name: 'Tax Cuts and Jobs Act Permanence',
					shortName: 'TCJA Permanence',
					year: 2026,
				},
				results: {
					gross_income: 48000,
					employee_payroll_tax: 2386.8,
					employer_payroll_tax: 2386.8,
					ordinary_income_after_401k: 31200,
					agi: 35000.0,
					taxable_income: 1100.0,
					deduction_type: 'standard',
					deductions: 33900.0,
					personal_exemption_amt: 0.0,
					pease_limitation_amt: 0,
					qbi_ded: 0.0,
					income_tax_before_credits: 110.0,
					qualified_income_tax: 110.0,
					selected_tax_before_credits: 110.0,
					amt_taxable_income: 0,
					amt: 0,
					income_tax_before_credits_with_amt: 110.0,
					ctc: 0,
					actc: 0,
					eitc: 0,
					dep_credit: 0.0,
					income_tax_after_nonrefundable_credits: 110.0,
					medicare_surtax: 0,
					niit: 0.0,
					sched_se_tax: 0,
					income_tax_after_other_taxes: 110.0,
					income_tax_after_credits: 110.0,
					total_payroll_tax: 4773.6,
					cash_income: 50386.8,
					tax_burden: 2496.8,
					tax_wedge: 4883.6,
					take_home_pay: 45503.200000000004,
					avg_effective_tax_rate: 0.09692220978510245,
					avg_effective_tax_rate_wo_payroll: 0.0022916666666666667,
					marginal_income_tax_rate: 0.2265,
					marginal_business_income_tax_rate: 0.2607,
				},
			},
		],
	},
] as const satisfies Profile[];

export default profiles;

export type ProfileId = (typeof profiles)[number]['description']['id'];

export type ProfileOption = ProfileId | 'custom';
