import { useRef, useEffect } from 'react';
import FilingStatus from './FilingStatus';
import IncomeInputs from './IncomeInputs';
import DependentsInput from './DependentsInput';
import DeductionSelect from './DeductionSelect';
import Button from './Button';
import { DeductionStatus, Taxpayer } from '../types';
import styled from 'styled-components';

const Form = styled.form`
	background-color: ${props => props.theme.gray900};
	display: block;
	margin-top: 2rem;
	padding: 2rem;
`;

interface CustomInputsProps {
	scroll: (ref: HTMLElement) => void;
	calculate: () => void;
	taxpayer: Taxpayer;
	deduction: DeductionStatus;
	updateInput: (value: string | number, id: keyof Taxpayer) => void;
	updateDeduction: (value: DeductionStatus) => void;
}

function CustomInputs({
	scroll,
	calculate,
	taxpayer,
	deduction,
	updateInput,
	updateDeduction,
}: CustomInputsProps) {
	const customInputsRef = useRef<HTMLFormElement>(null);
	useEffect(() => {
		if (customInputsRef.current) {
			scroll(customInputsRef.current);
		}
	}, [scroll]);

	return (
		<Form
			ref={customInputsRef}
			onSubmit={e => {
				e.preventDefault();
				calculate();
			}}
		>
			<FilingStatus
				name="Choose a Filing Status?"
				selected={taxpayer.filing_status}
				update={updateInput}
			/>
			{taxpayer.filing_status !== undefined ? (
				<div>
					<IncomeInputs
						scroll={scroll}
						calculate={calculate}
						taxpayer={taxpayer}
						updateInput={updateInput}
					/>
					<DependentsInput
						calculate={calculate}
						taxpayer={taxpayer}
						updateInput={updateInput}
					/>
					<DeductionSelect
						calculate={calculate}
						deduction={deduction}
						updateDeduction={updateDeduction}
						updateInput={updateInput}
					/>
					<Button type="submit" value="submit">
						Calculate
					</Button>
				</div>
			) : null}
		</Form>
	);
}

export default CustomInputs;
