import styled from 'styled-components';
import { formatter, Format, colorForValue } from '../helpers';
import Theme from '../../Theme';

const Th = styled.th`
	background-color: #fff;
	border-bottom: 1px solid ${props => props.theme.border};
	border-left: 1px solid ${props => props.theme.border};
	font-size: 0%.9rem;
	font-weight: 700;
	padding: 6px 8px;
	text-align: left;
	vertical-align: middle;

	&:first-child {
		border-left: 0;
	}
`;

const Td = styled.td`
	border-bottom: 1px solid ${props => props.theme.border};
	border-left: 1px solid ${props => props.theme.border};
	font-family: ${props => props.theme.fontFamilyMono};
	padding: 6px 8px;

	&:first-child {
		border-left: 0;
	}
`;

const rowFormatter = (
	i: number,
	value: number | string,
	format: Format,
	desirable: boolean,
) => {
	if (i === 0) {
		return value;
	} else if (i === 3) {
		return (
			<span
				style={{ color: colorForValue(value as number, desirable ? '+' : '-') }}
			>
				{formatter(value, format)}
			</span>
		);
	} else {
		return formatter(value, format);
	}
};

interface TableRowProps {
	cells: { value: number | string; tooltip?: string }[];
	header?: boolean;
	section?: string;
	id?: string;
	format: Format;
	desirable?: boolean;
	level?: string;
}

const TableRow = ({
	cells,
	header,
	section,
	id,
	format,
	desirable = false,
	level,
}: TableRowProps) => {
	return (
		<tr>
			{cells.map((c, i) => {
				if (header) {
					return <Th key={`th-${c.value}`}>{c.value}</Th>;
				} else {
					return (
						<Td
							data-tooltip-id={
								c.tooltip ? `tax-table-section-${section}` : undefined
							}
							data-tooltip-content={c.tooltip ? c.tooltip : undefined}
							key={`${id}-${c.value}-${i}`}
							style={{
								color: c.tooltip ? Theme.primary : undefined,
								textAlign: i > 0 ? 'right' : 'left',
								paddingLeft:
									level === 'top'
										? '0.5rem'
										: level === 'second'
											? '2.5rem'
											: '4.5rem',
								textDecoration: c.tooltip ? 'underline' : 'none',
								whiteSpace: i > 0 ? 'nowrap' : 'normal',
							}}
						>
							{rowFormatter(i, c.value, format, desirable)}
						</Td>
					);
				}
			})}
		</tr>
	);
};

export default TableRow;
