import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';
import { Taxpayer } from '../types';

interface DependentsInputProps {
	taxpayer: Taxpayer;
	updateInput: (value: string | number, id: keyof Taxpayer) => void;
	calculate: () => void;
}

const DependentsInput = ({
	taxpayer,
	updateInput,
	calculate,
}: DependentsInputProps) => {
	return (
		<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
			<NumericInput
				id="child_dep"
				name="Child Dependents"
				onChange={updateInput}
				onEnter={calculate}
				placeholder={taxpayer.child_dep}
				tooltip="Number of children below age 17"
			/>
			<NumericInput
				id="nonchild_dep"
				name="Non-Child Dependents"
				onChange={updateInput}
				onEnter={calculate}
				placeholder={taxpayer.nonchild_dep}
			/>
		</SimpleContainer>
	);
};

export default DependentsInput;
