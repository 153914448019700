import { format as d3Format } from 'd3-format';
import { Palette } from '../Theme';

const formats = [
	'shortDollar',
	'longDollar',
	'deltaDollar',
	'percent',
	'text',
] as const;

export type Format = (typeof formats)[number];

export function formatter(value: number | string, f?: Format): string {
	if (!f) return String(value);
	if (f === 'text' && typeof value === 'string') {
		return value.charAt(0).toUpperCase() + value.slice(1);
	} else if (typeof value === 'number') {
		switch (f) {
			case 'shortDollar':
				return d3Format('$,')(value);
			case 'longDollar':
				return d3Format('$,.2f')(value);
			case 'deltaDollar':
				return d3Format('+$,.2f')(value);
			case 'percent':
				return d3Format('.2%')(value);
			default:
				return String(value);
		}
	}

	throw Error(`Format ${f} not supported with value of type ${typeof value}.`);
}

const colorForValue = (value: number, sign: '+' | '-') => {
	const goodOrBadSign = sign === '+' ? 1 : -1;
	if (value * goodOrBadSign > 0) {
		return Palette.greenText;
	} else if (value * goodOrBadSign < 0) {
		return '#EF4438';
	} else {
		return '#333';
	}
};

export { formats, colorForValue };
