export function Logo() {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1122.8 165.4"
			xmlSpace="preserve"
			height="35px"
		>
			<g>
				<polygon
					fill="#ffffff"
					points="213.5,124.9 228.3,124.9 228.3,53.1 255.2,53.1 255.2,40.4 186.8,40.4 186.8,53.1 213.5,53.1 	"
				/>
				<path
					fill="#ffffff"
					d="M285.3,40.4h-15.7l-32.4,84.5h15.3l7.9-22.3h33.7l7.9,22.3h14.5l0.9-1.3L285.3,40.4z M264.4,91.4L277.2,55h0.2
		h0.1l12.7,36.4H264.4z"
				/>
				<polygon
					fill="#ffffff"
					points="333.4,124.9 354.8,92.6 375.9,124.9 393.8,124.9 363.9,81.1 391.8,40.4 375.3,40.4 355.3,70.2
		336.1,40.4 318.5,40.4 346.4,81.1 317.4,123.7 317.9,124.9 	"
				/>
			</g>
			<polygon fill="#ffffff" points="317.9,124.9 317.4,123.7 316.5,124.9 " />
			<g>
				<polygon
					fill="#ffffff"
					points="429.9,124.9 444.7,124.9 444.7,87.4 482.9,87.4 482.9,75.3 444.7,75.3 444.7,53.1 488.2,53.1
		488.2,40.4 429.9,40.4 	"
				/>
				<path
					fill="#ffffff"
					d="M528.8,38.5c-25.4,0-40.5,20.1-40.5,44.2c0,24.1,15.1,44.2,40.5,44.2c25.4,0,40.5-20.1,40.5-44.2
		C569.3,58.6,554.2,38.5,528.8,38.5 M528.8,114.7c-18.1,0-25.7-15.9-25.7-32.1c0-16.2,7.6-32.1,25.7-32.1
		c18.1,0,25.7,15.9,25.7,32.1C554.6,98.8,547,114.7,528.8,114.7"
				/>
				<path
					fill="#ffffff"
					d="M629.3,89.6c0,11.4-0.7,24.5-19.8,24.5c-19.1,0-19.8-13.1-19.8-24.5V40.4h-14.8v54.1
		c0,21.6,13.8,32.4,34.6,32.4c20.8,0,34.6-10.7,34.6-32.4V40.4h-14.8V89.6z"
				/>
				<polygon
					fill="#ffffff"
					points="707.7,102.7 707.5,102.7 669,40.4 653.5,40.4 653.5,124.9 667.5,124.9 667.5,62.8 667.9,62.8
		706.2,124.9 721.8,124.9 721.8,40.4 707.7,40.4 	"
				/>
				<path
					fill="#ffffff"
					d="M769.7,40.4h-35v84.5l35,0c26,0,37.2-19,37.2-42.2C806.9,59.4,795.6,40.4,769.7,40.4 M764,112.8h-14.5V52.5
		H764c23.1,0,28.1,13.2,28.1,30.1C792.1,99.6,787.1,112.8,764,112.8"
				/>
				<path
					fill="#ffffff"
					d="M838,40.4l-32.4,84.5h15.3l7.9-22.3h33.7l7.9,22.3h15.9l-32.5-84.5H838z M832.8,91.4L845.5,55h0.2h0.1
		l12.7,36.4H832.8z"
				/>
				<polygon
					fill="#ffffff"
					points="872,53.1 898.8,53.1 898.8,124.9 913.6,124.9 913.6,53.1 940.4,53.1 940.4,40.4 872,40.4 	"
				/>
				<rect x="945.5" y="40.4" fill="#ffffff" width="14.8" height="84.5" />
				<path
					fill="#ffffff"
					d="M1007.2,38.5c-25.4,0-40.5,20.1-40.5,44.2c0,24.1,15.1,44.2,40.5,44.2c25.4,0,40.5-20.1,40.5-44.2
		C1047.7,58.6,1032.5,38.5,1007.2,38.5 M1007.2,114.7c-18.1,0-25.7-15.9-25.7-32.1c0-16.2,7.6-32.1,25.7-32.1
		c18.1,0,25.7,15.9,25.7,32.1C1032.9,98.8,1025.3,114.7,1007.2,114.7"
				/>
				<polygon
					fill="#ffffff"
					points="1107.8,40.4 1107.8,102.7 1107.6,102.7 1069.1,40.4 1053.5,40.4 1053.5,124.9 1067.6,124.9
		1067.6,62.8 1067.9,62.8 1106.3,124.9 1121.8,124.9 1121.8,40.4 	"
				/>
			</g>
			<g>
				<path
					fill="#ffffff"
					d="M140.4,66.1c-1.8,0-2.4,1.9-2.4,3.6v0.7h4.7C142.6,67.7,142.3,66.1,140.4,66.1z"
				/>
				<path
					fill="#ffffff"
					d="M107.9,98c1.9,0,2.6-1.3,2.6-4.5c0-2.7-0.4-4.7-2.6-4.7c-2.3,0-2.6,2-2.6,4.7C105.3,96.8,106,98,107.9,98z"
				/>
				<path
					fill="#ffffff"
					d="M63.4,130.7l3.3,0.7l1.1,0.2l0,31.5c1,0.2,3.9,1,5,1.1v-31.5l3.1,0.7l3.1,0.7l1.8,0.5v30c0.3,0,0.5,0,0.8,0
		c0.8,0,2.8,0.2,3.6,0.2v-29.3l3.1,0.7l3.1,0.7l2.3,0.4l0,27.1c1.1-0.1,2.9-0.6,4-0.8v-25.4l3.1,0.7l3.1,0.7l2.1,0.6v21.6
		c1-0.3,3.1-1,4.1-1.3v-19.6l3.1,0.7l3.2,0.7l2.2,0.6v13.8c1-0.5,3-0.9,4-1.4v-11.7l3.1,0.6l3.1,0.7l2.2,0.6l-0.1,4.9
		c2.5-1.7,6-4.3,8.4-6.3l-80.6-17.4l-11.3,4.5l-14.4-2.3l-15.9,6c0.7,0.8,1.5,1.7,2.3,2.5l12.1-4.7v15.3c1,0.7,3.1,1.7,4.1,2.4
		v-17.5l9.8,1.5v22c1.3,0.6,3.4,1.7,4.8,2.2l0-24.6l7-2.9v29.9c1,0.3,3.7,1,4.8,1.2v-31.8L63.4,130.7z"
				/>
				<path
					fill="#ffffff"
					d="M82.7,0.6C37,0.6,0,37.6,0,83.2c0,17.2,5.3,33.2,14.3,46.5l2.4-1.1v-9c0-1.5,1.7-1.7,1.7-0.7c0,0,0,6,0,7.1
		c0,1.7,1.6,2.1,1.6,0.4c0-1.1,0-10.2,0-10.2c0-1.7,5.8-4.1,5.8-3.1c0,0-0.1,9.6-0.1,11.2c0,1.3,2.4,0.6,2.4-0.7
		c0-1.2,0-11.4,0-11.5c0-1.1,7-3.1,7.1-1.9c0.1,1.6-0.1,10.1-0.1,11.1c0,1.2,3.5,0.4,3.5-0.7c0-0.6,0-10.4,0-10.9
		c0-0.3,8.7-1.3,8.7-0.7c0,1.3,0,10.6,0,11.5c0,1.1,3.2,0.6,3.2-0.4c0-0.8,0-10.3,0-10.9c0-0.6,8.8-0.1,8.8,0.7c0,1.6,0,9.2,0,10.2
		c0,1.5,2.8,1.5,2.8,0c0-1.2,0-9.5,0-9.6c0.3-0.7,5.4,1.8,6.2,0.7c0,0,0.8-1.8-3.4-2.9c-21.6-5.6-36.4,0-43.1,3.5
		c-0.5,0.2-2,1-3.4,1.8c-1.3,0.8-2.4,1.6-2.3,1.4c0.7-3-0.5-3,4.7-5.7c13.3-7,30-7.8,46.2-3.6c3.6,0.9,4.4,0.2,4.3-0.6
		c-0.1-1.4-0.1-1.8-2.8-2.6c-5.3-1.6-29.1-7.4-50.1,4.3c-0.6,0.3-0.7-1,0.1-2c0.6-0.7,2-1.5,3.5-2.2l0-1l-1.4-1.3v-1
		c12.5-7.4,32.8-8.1,43-5.4c3.4,0.9,6,1.3,5.9,0.5c-0.1-1.6-6.1-3.5-7.5-3.8c-11.1-2.5-25.4-1.5-37.1,3.1c-1.5,0.6-2.5,1-2.6,0.7
		c-0.2-0.4,0.8-2.1,1.6-2.7c1.9-1.4,1.9-2.8,1.9-3.2c-0.1-0.4,0.1-1.6,0.4-2.3c0,0,3.8-9.7,15-16.8c0,0,0.9-3,3-4.6c0,0,0-3.3,0-4.2
		c0-0.9-1.5-2.8-1.5-2.8s-0.4-0.7,0-1.2c2.3-3.1,4.1-4.6,4.1-5.8c0-1.5,0.4-9.6,1.5-9.6c1.1,0,1.5,8.3,1.5,10c0,1.5,2.3,3,4,5.4
		c0.3,0.5-0.1,1.2-0.1,1.2s-1.2,1.5-1.3,2.7c-0.1,1.7,0,4.2,0,4.2c2.2,1.7,3,4.7,3,4.6c14.2,9.2,15,21,15.4,21.3
		c3.8,2.6,5.9,7.4,5.9,7.4s0.1,1,0,2.1c-0.1,1.1-1.6,2.4-1.6,2.4l0,2.2c2.8,1.6,5.9,5.1,6.6,6.4c0.4,0.8-0.3,3.8-0.3,3.8
		s-0.9,2-1.8,3.6c-0.2,0.3,0,5.5,0,5.5l63.8,15c13.5-14.7,21.7-34.3,21.7-55.9C165.3,37.6,128.3,0.6,82.7,0.6z"
				/>
			</g>
		</svg>
	);
}
