import styled from 'styled-components';
import { Logo } from '../images/logo';

const BannerStyle = styled.div`
	align-items: center;
	background-color: ${props => props.theme.primary};
	display: flex;
	height: 60px;
	justify-content: center;
	text-align: center;
	width: 100%;
`;

const Banner = () => {
	return (
		<BannerStyle>
			<a
				href="https://taxfoundation.org/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Logo />
			</a>
		</BannerStyle>
	);
};

export default Banner;
