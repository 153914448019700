import styled, { keyframes } from 'styled-components';
import Tooltip from './Tooltip';
import SimpleContainer from './SimpleContainer';
import { Format, formatter } from './helpers';
import { Taxpayer } from '../types';

const Animation = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0)
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const Label = styled.label`
	font-size: 1rem;
	font-weight: 400;
`;

const Input = styled.input`
	-moz-appearance: textfield;
	appearance: textfield;
	background-color: #ffffff;
	border: 1px solid ${props => props.theme.border};
	color: ${props => props.theme.primary};
	display: block;
	font-size: ${props => props.theme.fontSize};
	margin: 0;
	padding: 0.65rem;
	text-align: right;
	width: 100%;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	&:focus {
		border: 1px solid ${props => props.theme.tfBlue};
	}
`;

const Container = styled(SimpleContainer)`
	animation: ${Animation} 0.4s;
`;

interface NumericInputProps {
	id: keyof Taxpayer;
	name: string;
	tooltip?: string;
	disabled?: boolean;
	min?: number;
	placeholder: number;
	format?: Format;
	onChange: (value: string | number, id: keyof Taxpayer) => void;
	onEnter: () => void;
}
const NumericInput = ({
	id,
	name,
	tooltip,
	disabled,
	min,
	placeholder,
	format,
	onChange,
	onEnter,
}: NumericInputProps) => {
	return (
		<Container $rows={2} $columns={1} $gridGap="0.5rem">
			<Label htmlFor={`input-${id}`}>
				{tooltip ? (
					<>
						<span>{name}</span>
						<Tooltip tooltipId={`tooltip-${id}`} tooltipText={tooltip} />
					</>
				) : (
					name
				)}
			</Label>
			<Input
				disabled={disabled}
				id={`input-${id}`}
				min={min ?? 0}
				type="number"
				onChange={event => {
					onChange(event.target.value, id);
				}}
				onKeyUp={event => {
					if (event.key === 'Enter') {
						onEnter();
					}
				}}
				placeholder={
					format ? formatter(placeholder, format) : String(placeholder)
				}
			/>
		</Container>
	);
};

export default NumericInput;
