import { useCallback, useState } from 'react';
import Theme from './Theme';
import styled, { ThemeProvider, StyleSheetManager } from 'styled-components';
import Banner from './components/Banner';
import SocialButtons from './components/SocialButtons';
import ProfileSelect from './components/ProfileSelect';
import CustomInputs from './components/CustomInputs';
import { Comparison } from './components/comparison/Comparison';
import { DeductionStatus, Taxpayer } from './types';
import { PlanId, ProfileOption, TaxPlan } from './profiles';
import isPropValid from '@emotion/is-prop-valid';

const AppWrapper = styled.div`
	font-family: ${props => props.theme.fontFamily};
	font-size: ${props => props.theme.fontSize};
	margin: 0 auto;
	max-width: 720px;

	@media screen and (max-width: 720px) {
		padding: 0 1rem;
	}

	* {
		box-sizing: border-box;
	}
`;

function App() {
	const [taxpayer, setTaxpayer] = useState<Taxpayer>({
		business_income_service: 0,
		filing_status: undefined,
		child_dep: 0,
		nonchild_dep: 0,
		ordinary_income1: 0,
		ordinary_income2: 0,
		business_income: 0,
		ss_income: 0,
		qualified_income: 0,
		'401k_contributions': 0,
		medical_expenses: 0,
		sl_income_tax: 0,
		sl_property_tax: 0,
		interest_paid: 0,
		charity_contributions: 0,
		other_itemized: 0,
	});
	const [profile, setProfile] = useState<ProfileOption | undefined>(undefined);
	const [deduction, setDeduction] =
		useState<DeductionStatus>('standardDeduction');
	const [year, setYear] = useState<number>(2026);
	const [plan1, setPlan1] = useState<PlanId>('TCJA_perm_2026');
	const [plan2, setPlan2] = useState<PlanId>('current');
	const [taxes, setTaxes] = useState<TaxPlan[] | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	function onTF(referrer: string) {
		return (
			referrer.includes('localhost') || referrer.includes('taxfoundation.org')
		);
	}

	function calculate() {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		let input = taxpayer;

		/**
		 * Only count spouse income if married
		 */
		if (taxpayer.filing_status !== 1) {
			input = { ...input, ordinary_income2: 0 };
			updateInput(0, 'ordinary_income2');
		}

		/**
		 * Decide whether filer is 'single' or 'head of household'
		 */
		const hasDeps = taxpayer.child_dep > 0 || taxpayer.nonchild_dep > 0;

		if (taxpayer.filing_status === 0 && hasDeps) {
			input = { ...input, filing_status: 2 };
		} else if (taxpayer.filing_status === 2 && !hasDeps) {
			input = { ...input, filing_status: 0 };
		}

		/**
		 * Zero out deductions if you switch to standard again
		 */
		if (deduction === 'standardDeduction') {
			input = {
				...input,
				medical_expenses: 0,
				sl_income_tax: 0,
				sl_property_tax: 0,
				interest_paid: 0,
				charity_contributions: 0,
				other_itemized: 0,
			};
		}

		/**
		 * Craft request to taxsim API endpoint
		 */
		const request = new Request(import.meta.env.VITE_BACKEND_URL as string, {
			headers: headers,
			method: 'POST',
			mode: 'cors',
			body: JSON.stringify(input),
		});

		/**
		 * Get the results and set state. We DO NOT save inputs/outputs on server.
		 */
		fetch(request)
			.then(response => {
				if (response.status === 200) {
					return response.json();
				} else {
					throw new Error('Network unable to fulfill request');
				}
			})
			.then(data => {
				if (error) setError(undefined);
				updateTaxes(data as TaxPlan[]);
			})
			.catch(error => {
				if (error instanceof Error) setError(error.message);
			});
	}

	function updateTaxes(taxes?: TaxPlan[]) {
		setTaxes(taxes);
	}

	function updateYear(year: string | number) {
		const plansInYear = [
			...new Set(
				taxes?.filter(t => +t.plan.year === +year).map(t => t.plan.id),
			),
		];
		if (!plansInYear.includes(plan1)) {
			updatePlan('plan1', 'current');
		}
		if (!plansInYear.includes(plan2)) {
			updatePlan('plan2', 'TCJA_perm_2026');
		}
		setYear(+year);
	}

	function updatePlan(plan: 'plan1' | 'plan2', id: PlanId) {
		if (plan === 'plan1') {
			setPlan1(id);
		}
		if (plan === 'plan2') {
			setPlan2(id);
		}
	}

	function updateInput(value: number | string, id: keyof Taxpayer) {
		setTaxpayer(prev => {
			const newTaxpayer = { ...prev, [id]: +value };
			return newTaxpayer;
		});
	}

	function updateProfile(profile: ProfileOption) {
		setProfile(profile);
		if (error) setError(undefined);
	}

	function updateDeduction(deduction: DeductionStatus) {
		setDeduction(deduction);
	}

	const scrollToPlace = useCallback((element: HTMLElement) => {
		const offset = element.getBoundingClientRect().top - 70;
		window.scrollBy({ top: offset, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<StyleSheetManager
			shouldForwardProp={isPropValid}
			enableVendorPrefixes={true}
		>
			<ThemeProvider theme={Theme}>
				<AppWrapper id="app">
					{window.location !== window.parent.location &&
					onTF(document.referrer) ? null : (
						<div>
							<Banner />
							<h1 style={{ textAlign: 'center', fontWeight: 700 }}>
								How Will Tax Cuts and Jobs Act Expirations Impact You?
							</h1>
						</div>
					)}
					<SocialButtons
						size="30px"
						message="How will the Tax Cuts and Jobs Act impact after-tax incomes? This new calculator allows you to calculate a custom scenario."
						hashtags="TaxReform"
						emailSubject="Tax Reform Calculator"
						emailBody={`The Tax Foundation's tax reform calculator shows you how the Tax Cuts and Jobs Act will impact after-tax income, total taxes owed, effective tax rates, and more. You can calculate a custom scenario here: ${window.location.href}`}
					/>
					<ProfileSelect
						selection={profile}
						updateProfile={updateProfile}
						updateTaxes={updateTaxes}
					/>
					{profile === 'custom' ? (
						<CustomInputs
							scroll={scrollToPlace}
							calculate={calculate}
							deduction={deduction}
							taxpayer={taxpayer}
							updateDeduction={updateDeduction}
							updateInput={updateInput}
						/>
					) : null}
					{taxes ? (
						<Comparison
							taxes={taxes}
							year={year}
							yearsAvailable={Array.from(new Set(taxes.map(t => t.plan.year)))}
							plan1={plan1}
							plan2={plan2}
							updatePlan={updatePlan}
							updateYear={updateYear}
							scroll={scrollToPlace}
						/>
					) : null}
					{error ? (
						<div>
							<h2>We&apos;re Sorry, Something Went Wrong</h2>
							<p>
								The calculator was unable to create an estimate. Please review
								your input and try again later.
							</p>
							<p>
								Error returned:{' '}
								<span style={{ fontFamily: 'monospace' }}>{error}</span>
							</p>
						</div>
					) : null}
				</AppWrapper>
			</ThemeProvider>
		</StyleSheetManager>
	);
}

export default App;
