import { useState } from 'react';
import styled from 'styled-components';
import TableRow from './TableRow';
import Button from '../Button';
import { resultsMetadata } from '../../resultsMetadata';
import { TaxPlan } from '../../profiles';
import { Tooltip } from 'react-tooltip';

interface DetailItem {
	name: string;
	id: string;
	items: {
		id: keyof TaxPlan['results'];
		level: 'top' | 'second' | 'third';
	}[];
}

const DETAIL_ITEMS: DetailItem[] = [
	{
		name: 'Overview of Tax Burden',
		id: 'taxes',
		items: [
			{ id: 'cash_income', level: 'top' },
			{ id: 'gross_income', level: 'top' },
			{ id: 'agi', level: 'top' },
			{ id: 'taxable_income', level: 'top' },
			{ id: 'income_tax_after_credits', level: 'top' },
			{ id: 'total_payroll_tax', level: 'top' },
			{ id: 'tax_wedge', level: 'top' },
			{ id: 'avg_effective_tax_rate', level: 'top' },
		],
	},
	{
		name: 'Income Tax Calculation',
		id: 'tax-calc',
		items: [
			{ id: 'agi', level: 'top' },
			{ id: 'deductions', level: 'second' },
			{ id: 'qbi_ded', level: 'third' },
			{ id: 'personal_exemption_amt', level: 'second' },
			{ id: 'taxable_income', level: 'top' },
			{ id: 'selected_tax_before_credits', level: 'top' },
		],
	},
	{
		name: 'Alternative Minimum Tax',
		id: 'amt',
		items: [
			{ id: 'amt_taxable_income', level: 'top' },
			{ id: 'amt', level: 'top' },
		],
	},
	{
		name: 'Credits, Payments, and Other Taxes',
		id: 'other',
		items: [
			{ id: 'income_tax_before_credits_with_amt', level: 'top' },
			{ id: 'ctc', level: 'second' },
			{ id: 'dep_credit', level: 'second' },
			{ id: 'income_tax_after_nonrefundable_credits', level: 'top' },
			{ id: 'niit', level: 'second' },
			{ id: 'medicare_surtax', level: 'second' },
			{ id: 'sched_se_tax', level: 'second' },
			{ id: 'income_tax_after_other_taxes', level: 'top' },
			{ id: 'actc', level: 'second' },
			{ id: 'eitc', level: 'second' },
			{ id: 'income_tax_after_credits', level: 'top' },
		],
	},
];

function getDifference(
	value1: number | string,
	value2: number | string,
): number | string {
	if (typeof value1 === 'number' && typeof value2 === 'number') {
		return value2 - value1;
	} else {
		return '';
	}
}

const StyledTable = styled.table`
	background-color: #fff;
	border: 1px solid ${props => props.theme.border};
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 0.9rem;
	width: 100%;
`;

interface TableSectionProps {
	plan1: TaxPlan;
	plan2: TaxPlan;
	section: DetailItem;
}

function TableSection({ plan1, plan2, section }: TableSectionProps) {
	const header = [
		{
			value: 'Line Item',
		},
		{
			value: plan1.plan.name,
		},
		{
			value: plan2.plan.name,
		},
		{
			value: 'Difference',
		},
	];

	return (
		<div>
			<h2>{section.name}</h2>
			<StyledTable>
				<thead>
					<TableRow header cells={header} format="text" />
				</thead>
				<tbody>
					{section.items.map(item => {
						const meta = resultsMetadata.find(r => r.id === item.id);
						if (!meta) {
							return null;
						}
						const p1 = plan1.results[item.id];
						const p2 = plan2.results[item.id];
						const format = meta.format;
						const cells = [
							{ value: meta.name, tooltip: meta.tooltip },
							{ value: p1 },
							{ value: p2 },
							{ value: getDifference(p1, p2) },
						];
						return (
							<TableRow
								key={`row-${item.id}`}
								format={format}
								cells={cells}
								section={section.id}
								id={item.id}
								desirable={meta.desirable}
								level={item.level}
							/>
						);
					})}
				</tbody>
			</StyledTable>
			<Tooltip
				id={`tax-table-section-${section.id}`}
				style={{ maxWidth: '300px' }}
			/>
		</div>
	);
}

interface TaxTableProps {
	plan1: TaxPlan;
	plan2: TaxPlan;
}

function TaxTable({ plan1, plan2 }: TaxTableProps) {
	const [detail, setDetail] = useState(false);

	function toggleDetail() {
		setDetail(prev => !prev);
	}

	return (
		<div>
			{detail
				? DETAIL_ITEMS.map(item => (
						<TableSection
							key={`table-section-${item.id}`}
							plan1={plan1}
							plan2={plan2}
							section={item}
						/>
					))
				: null}
			<Button onClick={toggleDetail}>{`See ${
				detail ? 'Less' : 'More'
			} Detail`}</Button>
		</div>
	);
}

export default TaxTable;
