import { useRef, useEffect } from 'react';
import { TaxPlan, PlanId } from '../../profiles';
import PlanChoice from './PlanChoice';
import ComparisonSummary from './ComparisonSummary';

interface ComparisonProps {
	taxes: TaxPlan[];
	year: number;
	yearsAvailable: number[];
	plan1: PlanId;
	plan2: PlanId;
	updateYear: (year: number) => void;
	updatePlan: (plan: 'plan1' | 'plan2', value: PlanId) => void;
	scroll: (ref: HTMLElement) => void;
}

export function Comparison({
	taxes,
	year,
	yearsAvailable,
	plan1,
	plan2,
	updatePlan,
	updateYear,
	scroll,
}: ComparisonProps) {
	const comparisonRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (comparisonRef.current) {
			scroll(comparisonRef.current);
		}
	}, [scroll]);

	return (
		<div ref={comparisonRef}>
			<PlanChoice
				year={year}
				plan1={plan1}
				updateYear={updateYear}
				updatePlan={updatePlan}
				yearsAvailable={yearsAvailable}
				plansAvailable={taxes
					.filter(
						taxPlan => taxPlan.plan.year === year && taxPlan.plan.id !== plan2,
					)
					.map(t => t.plan)}
			/>
			<ComparisonSummary
				plan1={taxes.find(t => t.plan.year === year && t.plan.id === plan1)}
				plan2={taxes.find(t => t.plan.year === year && t.plan.id === plan2)}
			/>
		</div>
	);
}
