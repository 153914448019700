import styled, { keyframes } from 'styled-components';
import Tooltip from './Tooltip';
import { Taxpayer } from '../types';

const Animation = keyframes`
  from {
    opacity: 0;
    transform: scaleY(0)
  }

  to {
    opacity: 1;
    transform: scaleY(1);
  }
`;

interface ContainerProps {
	columns?: string;
	rows?: string;
	gridGap?: string;
}

const Container = styled.div<ContainerProps>`
	align-content: end;
	animation: ${Animation} 0.4s;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto;
	margin: 1rem 0 0;

	@media (max-width: ${props => props.theme.mobileWidth}) {
		grid-template: auto / auto;
	}
`;

interface LabelProps {
	tooltip?: boolean;
}

const Label = styled.label<LabelProps>`
	align-items: center;
	display: grid;
	font-size: 1rem;
	font-weight: 400;
	grid-template-columns: ${props => (props.tooltip ? '1fr auto' : '1fr')};
`;

const Input = styled.input`
	accent-color: ${props => props.theme.primary};
	display: block;
	width: 2rem;

	&:focus {
		border: 1px solid ${props => props.theme.tfBlue};
	}
`;

interface CheckboxInputProps {
	id: keyof Taxpayer;
	name: string;
	tooltip?: string;
	placeholder?: string;
	yes: string;
	no: string;
	disabled?: boolean;
	onChange: (value: string | number, id: keyof Taxpayer) => void;
}

const CheckboxInput = ({
	id,
	name,
	tooltip,
	placeholder,
	yes,
	no,
	disabled,
	onChange,
}: CheckboxInputProps) => {
	return (
		<>
			<Container columns="2" rows="1" gridGap="0.5rem">
				<Input
					disabled={disabled}
					id={`input-${id}`}
					type="checkbox"
					onChange={event => {
						const value = event.target.checked ? yes : no;
						onChange(value, id);
					}}
					value={placeholder}
				/>
				<Label tooltip={tooltip !== undefined} htmlFor={`input-${id}`}>
					{tooltip ? (
						<>
							<span>{name}</span>
							<Tooltip tooltipId={`tooltip-${id}`} tooltipText={tooltip} />
						</>
					) : (
						name
					)}
				</Label>
			</Container>
		</>
	);
};

export default CheckboxInput;
