import { useState, useEffect, useRef } from 'react';
import Button from './Button';
import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';
import CheckboxInput from './CheckboxInput';
import { Taxpayer } from '../types';

interface IncomeInputsProps {
	taxpayer: Taxpayer;
	updateInput: (value: string | number, id: keyof Taxpayer) => void;
	calculate: () => void;
	scroll: (ref: HTMLElement) => void;
}

function IncomeInputs({
	taxpayer,
	updateInput,
	calculate,
	scroll,
}: IncomeInputsProps) {
	const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
	const scrollTarget = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (scrollTarget.current) {
			scroll(scrollTarget.current);
		}
	}, [scroll]);

	function toggleShowMore() {
		setShowAdditionalInputs(prev => !prev);
	}

	return (
		<div ref={scrollTarget}>
			<SimpleContainer
				$rows={1}
				$columns={taxpayer.filing_status === 1 ? 2 : 1}
				$gridGap="1rem"
			>
				<NumericInput
					id="ordinary_income1"
					format="shortDollar"
					name="Personal Income"
					onChange={updateInput}
					onEnter={calculate}
					placeholder={taxpayer.ordinary_income1}
				/>
				{taxpayer.filing_status === 1 ? (
					<NumericInput
						id="ordinary_income2"
						format="shortDollar"
						name="Spouse's Income"
						onChange={updateInput}
						onEnter={calculate}
						placeholder={taxpayer.ordinary_income2}
					/>
				) : null}
			</SimpleContainer>
			{showAdditionalInputs ? (
				<>
					<SimpleContainer $rows={1} $columns={1} $gridGap="1rem">
						<NumericInput
							id="401k_contributions"
							format="shortDollar"
							name="Pre-tax 401(k) Contributions"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
						/>
					</SimpleContainer>
					<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
						<NumericInput
							id="ss_income"
							format="shortDollar"
							name="Social Security Benefits"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={taxpayer.ss_income}
							tooltip="When adding any Social Security benefits received, this calculator will automatically assume the taxpayer qualifies for the additional standard deduction."
						/>
						<NumericInput
							id="qualified_income"
							format="shortDollar"
							name="Long-term Capital Gains and/or Qualified Dividends"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={taxpayer.qualified_income}
						/>
					</SimpleContainer>
					<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
						<NumericInput
							id="business_income"
							format="shortDollar"
							name="Business Income"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={taxpayer.business_income}
						/>
						<CheckboxInput
							id="business_income_service"
							name="Business income is from a professional services business."
							yes="1"
							no="0"
							onChange={updateInput}
							placeholder={String(taxpayer.business_income_service)}
							tooltip="Professional service businesses include businesses in fields of the health, law, accounting, consulting, financial services, among others."
						/>
					</SimpleContainer>
				</>
			) : null}
			{showAdditionalInputs ? null : (
				<Button
					inverted
					onClick={e => {
						e.preventDefault();
						toggleShowMore();
					}}
				>
					Show More Income Options
				</Button>
			)}
		</div>
	);
}

export default IncomeInputs;
