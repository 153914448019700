import styled from 'styled-components';

interface ButtonProps {
	inverted?: boolean;
	active?: boolean;
}

const Button = styled.button<ButtonProps>`
	background-color: ${props =>
		props.inverted ? props.theme.primary : props.theme.secondary};
	border: none;
	color: ${props => (props.inverted ? '#ffffff' : props.theme.navy)};
	cursor: pointer;
	display: block;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.1em;
	margin: 1rem auto 0 !important;
	padding: 1.125rem 1.875rem;
	text-transform: uppercase;
	transition: all 0.5s ease;

	&:hover {
		background-color: ${props =>
			props.inverted ? props.theme.primaryHover : props.theme.secondaryHover};
	}
`;

export default Button;
