import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const TooltipIcon = styled.span`
	border: 1px solid ${props => props.theme.border};
	border-radius: 50%;
	color: ${props => props.theme.color};
	cursor: pointer;
	display: inline-block;
	font-family: ${props => props.theme.fontFamilyMono};
	font-size: 0.9rem;
	height: 21px;
	padding: 0 5px;
	margin-left: 1.5rem;
	width: 21px;
`;

interface TooltipProps {
	tooltipId?: string;
	tooltipText?: string;
}

const Tooltip = ({ tooltipId, tooltipText }: TooltipProps) => {
	if (!tooltipId || !tooltipText) {
		return null;
	}

	return (
		<>
			<TooltipIcon data-tooltip-id={tooltipId}>?</TooltipIcon>
			<ReactTooltip id={tooltipId}>
				<p style={{ maxWidth: '300px' }}>{tooltipText}</p>
			</ReactTooltip>
		</>
	);
};

export default Tooltip;
