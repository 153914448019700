import styled from 'styled-components';
import { ShareIcons } from '../images/Images';

const Container = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template: 30px / repeat(4, 30px);
	justify-content: center;
	padding: 0;
`;

interface SocialButtonsProps {
	size: string;
	message: string;
	hashtags: string;
	emailSubject: string;
	emailBody: string;
}

const SocialButtons = ({
	size,
	message,
	hashtags,
	emailSubject,
	emailBody,
}: SocialButtonsProps) => {
	return (
		<Container>
			{ShareIcons.IconTwitter({
				color: '#00b6f1',
				fill: '#fff',
				height: size,
				width: size,
				message: message,
				hashtags: hashtags,
			})}
			{ShareIcons.IconFacebook({
				color: '#3b5998',
				fill: '#fff',
				height: size,
				width: size,
			})}
			{ShareIcons.IconLinkedIn({
				color: '#007bb6',
				fill: '#fff',
				height: size,
				width: size,
				message: message,
			})}
			{ShareIcons.IconEmail({
				color: '#df4a32',
				fill: '#fff',
				height: size,
				width: size,
				emailSubject: emailSubject,
				emailBody: emailBody,
			})}
		</Container>
	);
};

export default SocialButtons;
