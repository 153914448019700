import React from 'react';
import { colorForValue } from './helpers';

interface ColorizeNumberProps {
	value: number;
	sign: '+' | '-';
	children: React.ReactNode;
}

const ColorizeNumber = ({ value, sign, children }: ColorizeNumberProps) => {
	const goodOrBadSign = sign === '+' ? 1 : -1;
	if (value * goodOrBadSign > 0) {
		return (
			<span style={{ color: colorForValue(value, sign) }}>
				{sign === '+' ? (
					<>
						<span>+</span>
						{children}
					</>
				) : (
					children
				)}
			</span>
		);
	} else if (value * goodOrBadSign < 0) {
		return (
			<span style={{ color: colorForValue(value, sign) }}>{children}</span>
		);
	} else {
		return <span>{children}</span>;
	}
};

export default ColorizeNumber;
