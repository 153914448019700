import styled from 'styled-components';

interface GraphicButtonProps {
	selected?: boolean;
}

const GraphicButton = styled.div<GraphicButtonProps>`
	align-items: center;
	background-color: ${props =>
		props.selected ? props.theme.green100 : '#fff'};
	border: 1px solid
		${props => (props.selected ? props.theme.green : props.theme.border)};
	cursor: pointer;
	display: grid;
	grid-gap: 1rem;
	grid-template: auto / 1fr 3fr;
	padding: 0.5rem 1rem;

	&:hover {
		background-color: ${props => props.theme.green};
		border: 1px solid ${props => props.theme.green};
	}

	@media (max-width: 500px) {
		padding: 0.6rem;
	}

	svg {
		max-height: 80px;
		max-width: 100%;
	}
`;

export default GraphicButton;
