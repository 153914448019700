import SimpleContainer from './SimpleContainer';
import GraphicButton from './GraphicButton';
import { GuiIcons } from '../images/Images';
import { Taxpayer } from '../types';

interface Status {
	id: keyof typeof GuiIcons;
	digit: number;
	name: string;
}

interface FilingStatusProps {
	name: string;
	selected?: number;
	update: (value: string | number, id: keyof Taxpayer) => void;
}

const FilingStatus = ({ name, selected, update }: FilingStatusProps) => {
	const statuses: Status[] = [
		{ id: 'single', digit: 0, name: 'Single Filer' },
		{ id: 'married', digit: 1, name: 'Married Filing Jointly' },
	];

	return (
		<div>
			<h2 style={{ marginTop: 0 }}>{name}</h2>
			<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
				{statuses.map(s => {
					return (
						<GraphicButton
							key={`filing_status-${s.id}`}
							onClick={() => {
								update(s.digit, 'filing_status');
								if (s.id !== 'married') {
									update(0, 'ordinary_income2');
								}
							}}
							selected={s.digit === selected}
						>
							{GuiIcons[s.id]({ height: 36 })}
							<p>{s.name}</p>
						</GraphicButton>
					);
				})}
			</SimpleContainer>
		</div>
	);
};

export default FilingStatus;
