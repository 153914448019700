import styled from 'styled-components';
import { Plan, PlanId } from '../../profiles';

const StyledYearSelect = styled.select`
	appearance: none;
	border: 1px solid ${props => props.theme.primary};
	background-color: #ffffff;
	color: ${props => props.theme.primary};
	cursor: pointer;
	font-family: ${props => props.theme.fontFamily};
	font-size: ${props => props.theme.h2Size};
	font-weight: 700;
	margin-right: 0.5rem;
	padding: 0 0.5rem;

	&:hover {
		color: ${props => props.theme.primaryHover};
	}
`;

const StyledYearLabel = styled.label`
	font-size: ${props => props.theme.h2Size};
	font-weight: 700;
`;

const StyledSingleYearLabel = styled.div`
	font-size: ${props => props.theme.h2Size};
	font-weight: 700;
`;

const StyledTabGroup = styled.div`
	border-bottom: 1px solid ${props => props.theme.border};
	box-sizing: border-box;
`;

interface StyledTabProps {
	active: boolean;
}

const StyledTab = styled.button<StyledTabProps>`
	background-color: #ffffff;
	border: 1px solid ${props => props.theme.border};
	border-top: 1px solid
		${props => (props.active ? props.theme.secondary : props.theme.border)};
	border-bottom: 1px solid
		${props => (props.active ? '#ffffff' : props.theme.border)};
	box-shadow: ${props =>
		props.active ? `0 3px 0 0 ${props.theme.secondary} inset` : 'none'};
	cursor: pointer;
	font-size: ${props => props.theme.fontSize};
	font-weight: 600;
	margin-left: -1px;
	margin-bottom: -1px;
	padding: 0.4rem 0.5rem;
`;

interface PlanChoiceProps {
	year: number;
	yearsAvailable: number[];
	plan1: PlanId;
	plansAvailable: Plan[];
	updateYear: (year: number) => void;
	updatePlan: (plan: 'plan1' | 'plan2', value: PlanId) => void;
}

const PlanChoice = ({
	year,
	yearsAvailable,
	plan1,
	plansAvailable,
	updateYear,
	updatePlan,
}: PlanChoiceProps) => {
	return (
		<div style={{ marginTop: '1rem' }}>
			{plansAvailable.length > 1 && (
				<StyledTabGroup>
					{plansAvailable.map(plan => (
						<StyledTab
							key={plan.id}
							active={plan.id === plan1}
							onClick={() => {
								updatePlan('plan1', plan.id);
							}}
						>
							{plan.shortName}
						</StyledTab>
					))}
				</StyledTabGroup>
			)}
			<div style={{ marginTop: '1rem' }}>
				{yearsAvailable.length > 1 ? (
					<div>
						<StyledYearSelect
							value={year}
							onChange={e => {
								updateYear(+e.target.value);
							}}
							name="yearSelect"
							id="yearSelect"
						>
							{yearsAvailable.map(y => (
								<option key={`year-option-${y}`} value={y}>
									{y}
								</option>
							))}
						</StyledYearSelect>
						<StyledYearLabel htmlFor="yearSelect">
							Tax Liability
						</StyledYearLabel>
					</div>
				) : (
					<StyledSingleYearLabel>{`${year} Tax Liability`}</StyledSingleYearLabel>
				)}
			</div>
		</div>
	);
};

export default PlanChoice;
