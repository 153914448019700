import styled from 'styled-components';

interface SimpleContainerProps {
	$columns: number;
	$rows: number;
	$gridGap?: string;
}

const SimpleContainer = styled.div<SimpleContainerProps>`
	align-content: end;
	display: grid;
	grid-gap: ${props => props.$gridGap};
	grid-template-columns: ${props =>
		props.$columns > 1 ? `repeat(${props.$columns}, 1fr)` : 'auto'};
	grid-template-rows: ${props =>
		props.$rows > 1 ? `repeat(${props.$rows}, auto)` : 'auto'};
	margin: 1rem 0 0;

	@media (max-width: ${props => props.theme.mobileWidth}) {
		grid-template: auto / auto;
	}
`;

export default SimpleContainer;
