import { Tooltip } from 'react-tooltip';
import { GuiIcons } from '../images/Images';
import SimpleContainer from './SimpleContainer';
import NumericInput from './NumericInput';
import GraphicButton from './GraphicButton';
import { DeductionStatus, Taxpayer } from '../types';

const DEDUCTIONS: { id: DeductionStatus; name: string; tooltip?: string }[] = [
	{ id: 'standardDeduction', name: 'Standard Deduction' },
	{
		id: 'itemize',
		name: 'Itemize Deductions',
		tooltip:
			'This calculator will automatically choose the standard deduction if it is advantageous.',
	},
];

interface DeductionSelectProps {
	deduction: DeductionStatus;
	updateDeduction: (deduction: DeductionStatus) => void;
	updateInput: (value: string | number, id: keyof Taxpayer) => void;
	calculate: () => void;
}

const DeductionSelect = ({
	deduction,
	updateDeduction,
	updateInput,
	calculate,
}: DeductionSelectProps) => {
	return (
		<div>
			<h2>Standard Deduction or Itemized?</h2>
			<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
				{DEDUCTIONS.map(d => {
					return (
						<GraphicButton
							data-tip={d.tooltip ? true : false}
							data-for={`tooltip-${d.id}`}
							key={`deductions-${d.id}`}
							onClick={() => {
								updateDeduction(d.id);
							}}
							selected={d.id === deduction}
						>
							{GuiIcons[d.id]({ height: 36 })}
							<p style={{ margin: 0 }}>{d.name}</p>
							{d.tooltip ? (
								<Tooltip id={`tooltip-${d.id}`}>
									<p style={{ maxWidth: '300px' }}>{d.tooltip}</p>
								</Tooltip>
							) : null}
						</GraphicButton>
					);
				})}
			</SimpleContainer>
			{deduction === 'itemize' ? (
				<div>
					<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
						<NumericInput
							id="medical_expenses"
							format="shortDollar"
							name="Medical Expenses"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
						/>
						<NumericInput
							id="sl_property_tax"
							format="shortDollar"
							name="State and Local Property Taxes"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
						/>
					</SimpleContainer>
					<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
						<NumericInput
							id="interest_paid"
							format="shortDollar"
							name="Mortgage Interest"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
							tooltip="For the sake of simplicity, this calculator will limit the mortgage interest deduction to $40,000 under current law post-TCJA expiration and $30,000 under TCJA extension. These limits were imputed using an assumed mortgage interest rate of 4%."
						/>
						<NumericInput
							id="sl_income_tax"
							format="shortDollar"
							name="State and Local Income Taxes"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
							tooltip="If the household chooses to deduct sales taxes instead of income taxes, enter the deduction for state and local sales taxes paid here."
						/>
					</SimpleContainer>
					<SimpleContainer $rows={1} $columns={2} $gridGap="1rem">
						<NumericInput
							id="charity_contributions"
							format="shortDollar"
							name="Charitable Contributions"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
						/>
						<NumericInput
							id="other_itemized"
							format="shortDollar"
							name="Other Itemized Deductions"
							onChange={updateInput}
							onEnter={calculate}
							placeholder={0}
							tooltip="Line 17 from Schedule A."
						/>
					</SimpleContainer>
				</div>
			) : null}
		</div>
	);
};

export default DeductionSelect;
